import React, { useEffect, useState, useMemo } from "react";

export default function Filter() {
  function FormField({ name, label, type, value, onChange, options }) {
    return (
      <div className="sm:col-span-3">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {label}:
        </label>
        <div className="mt-2">
          <select
            name={name}
            value={value}
            onChange={onChange}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  function ListItem({ provider, name, price, img, url }) {
    return (
      <li className="flex justify-between gap-x-6 py-5">
        <div className="flex min-w-0 gap-x-4">
          <img className="h-16 w-auto flex-none bg-gray-50" src={img} alt="" />

          <div className="min-w-0 flex-auto">
            <p className="text-sm font-semibold leading-6 text-gray-900">
              {name}
            </p>
            <p className="mt-1 truncate text-xs leading-5 text-gray-500">
              {provider}
            </p>
          </div>
        </div>

        <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
          <p className="text-sm leading-6 text-gray-900 font-bold">
            {formatPrice.format(price)} kr
          </p>
          <p className="mt-1 text-xs leading-5 text-gray-500">Läs mer</p>
        </div>
      </li>
    );
  }

  const formatPrice = useMemo(
    () =>
      new Intl.NumberFormat("sv-se", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    []
  );

  const [data, setData] = useState([]);
  const [solution, setSolution] = useState();
  const [accountRangeValue, setaccountRangeValue] = useState(10);
  const [dividendRangeValue, setdividendRangeValue] = useState(0);
  const [userInput, setUserInput] = useState({
    orderAmount: 5000 * accountRangeValue,
    orderQuantity: 500 / accountRangeValue,
    amountSaved: 500000,
    account: "ISK",
    market: "OMX Stockholm",
    discount: "Aktiespararna",
    dividend: dividendRangeValue,
  });

  const fetchData = async () => {
    try {
      const response = await fetch("./stocks.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const jsonData = await response.json();
      setData(jsonData.provider);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateInput = (updates) => {
    const updatedUserInput = {
      ...userInput,
      ...updates,
    };
    setUserInput(updatedUserInput);
  };

  const dividendHandler = (e) => {
    setdividendRangeValue(e);
    updateInput({ dividend: (userInput.dividend = 1000 * e) });
    handleCalculate();
  };

  const accountHandler = (e) => {
    setaccountRangeValue(e);
    updateInput({
      orderAmount: (userInput.orderAmount = 5000 * e),
      orderQuantity: (userInput.orderQuantity = 500 / e),
    });
    handleCalculate();
  };

  const handleInputChange = (e) => {
    // setaccountRangeValue(10);
    // setdividendRangeValue(0);
    const { name, value } = e.target;
    updateInput({ [name]: value });
    handleCalculate();
  };

  const handleCalculate = () => {
    const result = calculatePrice(
      userInput.market,
      userInput.discount,
      userInput.account
    );
    const lowestPrices = getLowestPrices(result);
    setSolution(lowestPrices);
  };

  const calculatePrice = (market, disc, acc) => {
    const resultObj = [];

    for (const item of data) {
      const calcArr = [];

      for (const solution of item.solutions) {
        const courtage = solution.courtage.find((c) => c.name === market);
        const discount = solution.discount;
        const offer = item.offer;
        const priceLimit = solution.priceLimit;

        const selectedAccount = acc;
        const accountInfo = solution.accounts.find(
          (account) => account.name === selectedAccount
        );

        if (
          (priceLimit.value &&
            userInput.orderAmount > priceLimit.amount &&
            priceLimit.amount) ||
          userInput.orderAmount < priceLimit.min
        ) {
          calcArr.push({
            provider: item.name,
            img: item.img,
            market: userInput.market,
            name: solution.name,
            price: false,
            reason: "Inactive solution",
          });
        } else if (
          courtage.value &&
          offer.value > userInput.amountSaved &&
          offer.amount > userInput.orderQuantity
        ) {
          calcArr.push({
            provider: item.name,
            img: item.img,
            market: userInput.market,
            name: solution.name,
            price: accountInfo && userInput.amountSaved * accountInfo.rate,
          });
        } else {
          if (courtage.value) {
            let price = 0;
            if (
              courtage.min &&
              courtage.rate * userInput.orderAmount > courtage.min
            ) {
              price =
                courtage.rate *
                  userInput.orderAmount *
                  userInput.orderQuantity +
                courtage.fixed * userInput.orderQuantity;
            } else if (!courtage.min) {
              price =
                courtage.rate * userInput.orderAmount * userInput.orderQuantity;
            } else if (!courtage.fixed) {
              price = courtage.min * userInput.orderQuantity;
            } else {
              price = courtage.fixed * userInput.orderQuantity;
            }

            if (
              courtage.max &&
              courtage.max < userInput.orderAmount * courtage.rate
            ) {
              price = courtage.max * userInput.orderQuantity;
            }

            if (disc === discount.name && discount.value) {
              price -= price * discount.discount;
            }

            if (userInput.account === "ISK") {
              price += userInput.amountSaved * 0.00882;
            } else if (userInput.account === "Aktie- och fondkonto") {
              price += userInput.dividend * 0.3;
            }

            if (accountInfo && accountInfo.value) {
              price += userInput.amountSaved * accountInfo.rate;
              calcArr.push({
                provider: item.name,
                img: item.img,
                market: userInput.market,
                name: solution.name,
                price,
              });
            } else {
              calcArr.push({
                provider: item.name,
                img: item.img,
                market: userInput.market,
                name: solution.name,
                price: false,
                reason: "No account",
              });
            }
          } else {
            calcArr.push({
              provider: item.name,
              img: item.img,
              market: userInput.market,
              name: solution.name,
              price: false,
              reason: "No market",
            });
          }
        }
      }
      resultObj.push({ name: item.name, result: calcArr });
    }

    return resultObj;
  };

  const sortNumbers = (numbers) => {
    const sortedNumbers = numbers.sort((a, b) => a.price - b.price);
    return sortedNumbers;
  };

  function getLowestPrices(results) {
    const lowestPrices = results.map(({ result }) => {
      const validPrices = result.filter(
        ({ price }) => typeof price === "number"
      );
      if (validPrices.length > 0) {
        const lowestPrice = Math.min(...validPrices.map(({ price }) => price));
        return validPrices.find(({ price }) => price === lowestPrice);
      } else {
        return null;
      }
    });
    return sortNumbers(lowestPrices.filter(Boolean));
  }

  return (
    <div>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Börsmäklarkollen
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Beräkna courtage per börsmäklare och marknad och se vilka alternativ
            på konto olika börsmäklare har med ränta och skatt. Skriv in dina
            värden eller använd reglaget.
          </p>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Snittaffär
              </label>
              <div className="mt-2">
                <input
                  name="orderAmount"
                  label="Snittaffär"
                  type="number"
                  value={userInput.orderAmount}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Antal affärer
              </label>
              <div className="mt-2">
                <input
                  name="orderQuantity"
                  label="Antal affärer"
                  type="number"
                  value={parseFloat(userInput.orderQuantity).toFixed(0)}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Samlat kapital
              </label>
              <div className="mt-2">
                <input
                  name="amountSaved"
                  label="Samlat kapital"
                  type="number"
                  value={userInput.amountSaved}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <FormField
              name="account"
              label="Konto"
              value={userInput.account}
              options={[
                "ISK",
                "Aktie- och fondkonto",
                "Kapitalförsäkring",
                "Inget satt",
              ]}
              onChange={handleInputChange}
            />
            <FormField
              name="discount"
              label="Rabatt"
              value={userInput.discount}
              options={["Aktiespararna", "Ingen rabatt"]}
              onChange={handleInputChange}
            />
            <FormField
              name="market"
              label="Marknad"
              value={userInput.market}
              options={[
                "OMX Stockholm",
                "Nasdaq OMX Nordic, Oslo Börs och First North",
                "NGM, NGM MTF och Spotlight",
                "Övrig handel",
              ]}
              onChange={handleInputChange}
            />
          </div>{" "}
          <div className="mt-6 flex items-center justify-start gap-x-6">
            <button
              onClick={handleCalculate}
              className="justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Kalkylera
            </button>
          </div>
          <div className="flex justify-between gap-x-6 block mt-2 text-sm font-medium text-gray-900 dark:text-white">
            <div>
              <p>
                <span className="text-[#dc2626] font-bold text-xl">- </span>
                Snittaffär{" "}
              </p>
              <p>
                <span className="text-[#84cc16] font-bold text-xl">+</span>{" "}
                Antal affärer{" "}
              </p>
            </div>
            <div>
              <p>
                <span className="text-[#84cc16] font-bold text-xl">+</span>{" "}
                Snittaffär{" "}
              </p>
              <p>
                <span className="text-[#dc2626] font-bold text-xl">-</span>{" "}
                Antal affärer{" "}
              </p>
            </div>
          </div>
          <input
            id="steps-range"
            type="range"
            min="1"
            max="19"
            value={accountRangeValue}
            onChange={(e) => accountHandler(e.target.value)}
            step="1"
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
          ></input>
          <label
            htmlFor="steps-range"
            className="block mt-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Utdelning
          </label>
          <input
            id="steps-range"
            type="range"
            min="0"
            max="100"
            value={dividendRangeValue}
            onChange={(e) => dividendHandler(e.target.value)}
            step="1"
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
          ></input>
          <p>{userInput.dividend}</p>
        </div>
      </div>
      <ul className="divide-y divide-gray-100">
        {solution &&
          solution.map((option) => (
            <ListItem
              name={option.name}
              price={option.price}
              provider={option.provider}
              img={option.img}
              market={option.market}
            />
          ))}
      </ul>
    </div>
  );
}

// Låta marknad styras av aktieportfölj
