import React from "react";
import Filter from "../components/Filter";

export default function HomePage() {
  return (
    <div className="bg-white">
      <div className="relative isolate px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-20 sm:py-20 ">
          <Filter />
        </div>
      </div>
    </div>
  );
}
