import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Homepage";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default function App() {
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          position: "relative",
          paddingBottom: "15vh",
        }}
      >
        <Router>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </>
  );
}
